.tabsContainer {
    border: 1px solid #888fa9;
    width: fit-content;
    max-width: auto;
    display: inline-flex;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.activeTab {
    font-weight: bold !important;
    color: #2445a7 !important;
    background: #f1f3f9 !important;
}

.tab {
    margin: 0;
    padding: 0 35px !important;
    height: 100%;
    color: #2445a7;
    background: white;
}

.tableHeadCell {
    width: 200px;
    font-weight: bold;
    text-transform: uppercase;
    color: #272727
}

.actionButton {
    border-radius: 6px;
    background: rgba(36, 69, 167, 0.1);
    padding: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}