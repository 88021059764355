.center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.text {
  margin-left: 1rem;
}
