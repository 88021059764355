.emailing {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 3px;
  align-items: center;
  padding: 4px 6px;

  background: #f1f2f4;
  border-radius: 4px;
  font-size: 0.9em;
  color: #4e5978;
}

.tooltip {
  display: inline-grid;
  grid-template-columns: auto;
  align-items: center;
  cursor: pointer;
}
