.notificationContainer {
  display: flex;
  align-items: center;
}

.notificationText{
  color: var(--gray-blue-light, #888FA9);
  text-align: center;
  font-family: Manrope;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
